import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Sun, Moon } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import LavaLamp from './LavaLamp';

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <div className={`min-h-screen relative overflow-hidden ${isDarkMode ? 'bg-black' : 'bg-gray-50'}`}>
      {/* Background */}
      <LavaLamp />
      {/* Content Container */}
      <div className="relative z-10">
        {/* Navigation Bar */}
        <nav className={`fixed top-4 left-1/2 transform -translate-x-1/2 w-11/12 max-w-6xl backdrop-blur-xl rounded-xl shadow-lg z-50 ${isDarkMode ? 'bg-zinc-900/5' : 'bg-white/5'}`}>
          <div className="flex items-center justify-between px-6 py-3">
            <div className="flex items-center space-x-2">
              <div className={`flex items-center justify-center w-10 h-10 ${isDarkMode ? 'text-white' : 'text-black'} transition-colors`}>
                <svg viewBox="0 0 100 100" className="w-full h-full">
                  <style>
                    {`
                      @keyframes orbit {
                        from {
                          transform: rotate(0deg) translateX(45px) rotate(0deg);
                        }
                        to {
                          transform: rotate(360deg) translateX(45px) rotate(-360deg);
                        }
                      }
                      @keyframes pulse {
                        0% {
                          transform: scale(1);
                          opacity: 1;
                        }
                        50% {
                          transform: scale(1.05);
                          opacity: 0.8;
                        }
                        100% {
                          transform: scale(1);
                          opacity: 1;
                        }
                      }
                      .electron {
                        animation: orbit 3s linear infinite;
                        transform-origin: 50% 50%;
                      }
                      .globe-pulse {
                        animation: pulse 4s ease-in-out infinite;
                        transform-origin: center;
                      }
                    `}
                  </style>
                  
                  {/* Globe with subtle pulse animation */}
                  <g className="globe-pulse">
                    <circle 
                      cx="50" 
                      cy="50" 
                      r="30" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2"
                    />
                    <path 
                      d="M20 50 C20 30 80 30 80 50 C80 70 20 70 20 50" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2"
                    />
                    <path 
                      d="M50 20 L50 80" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2"
                    />
                  </g>
                  
                  {/* Orbit path */}
                  <ellipse 
                    cx="50" 
                    cy="50" 
                    rx="45" 
                    ry="15" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    transform="rotate(-30, 50, 50)"
                    opacity="0.5"
                  />
                  
                  {/* Animated electron */}
                  <circle 
                    cx="50" 
                    cy="50" 
                    r="3" 
                    fill="currentColor" 
                    className="electron"
                  />
                </svg>
              </div>
              <Link to="/" className={`text-2xl font-semibold ml-2 ${isDarkMode ? 'text-gray-100' : 'text-gray-800'}`}>
                Mudit Dubey, PhD
              </Link>
            </div>
            
            <div className="hidden md:flex items-center space-x-6">
              {/* Theme Toggle */}
              <button 
                onClick={toggleTheme}
                className={`p-2 rounded-lg hover:bg-opacity-20 transition-colors ${
                  isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
                }`}
              >
                {isDarkMode ? (
                  <Sun className="w-6 h-6 text-gray-100" />
                ) : (
                  <Moon className="w-6 h-6 text-gray-800" />
                )}
              </button>

              {/* Navigation Links */}
              <Link 
                to="/#about" 
                className={`text-xl font-medium hover:text-gray-300 transition-colors ${
                  isDarkMode ? 'text-gray-100' : 'text-gray-800'
                }`}
              >
                About
              </Link>
              <Link 
                to="/#projects" 
                className={`text-xl font-medium hover:text-gray-300 transition-colors ${
                  isDarkMode ? 'text-gray-100' : 'text-gray-800'
                }`}
              >
                Projects
              </Link>
              <Link 
                to="/blog" 
                className={`text-xl font-medium hover:text-gray-300 transition-colors ${
                  isDarkMode ? 'text-gray-100' : 'text-gray-800'
                }`}
              >
                Articles
              </Link>
            </div>

            {/* Mobile Menu Button */}
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden"
            >
              {isMenuOpen ? (
                <X className={`w-6 h-6 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`} />
              ) : (
                <Menu className={`w-6 h-6 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`} />
              )}
            </button>
          </div>

          {/* Mobile Navigation Menu */}
          {isMenuOpen && (
            <div className={`md:hidden px-6 py-4 backdrop-blur-xl border-t rounded-b-xl ${isDarkMode ? 'bg-zinc-900/5 border-zinc-800/10' : 'bg-white/5 border-gray-200/10'}`}>
              <div className="flex flex-col space-y-6">
                <Link 
                  to="/#about" 
                  className={`text-lg ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  About
                </Link>
                <Link 
                  to="/#projects" 
                  className={`text-lg ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  Projects
                </Link>
                <Link 
                  to="/blog" 
                  className={`text-lg ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  Articles
                </Link>
              </div>
            </div>
          )}
        </nav>

        {/* Main Content */}
        <main className="relative z-10">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
