import React, { useEffect, useState } from 'react';

const generateRandomColor = () => {
  // Generate vibrant but not too bright colors
  const hue = Math.floor(Math.random() * 360);
  const saturation = 70 + Math.floor(Math.random() * 30); // 70-100%
  const lightness = 45 + Math.floor(Math.random() * 15);  // 45-60%
  return `hsla(${hue}, ${saturation}%, ${lightness}%, 0.5)`;
};

const LavaLamp = () => {
  const [colors, setColors] = useState({
    blob1: generateRandomColor(),
    blob2: generateRandomColor(),
    blob3: generateRandomColor(),
  });

  useEffect(() => {
    // Update colors when component mounts
    setColors({
      blob1: generateRandomColor(),
      blob2: generateRandomColor(),
      blob3: generateRandomColor(),
    });
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="lava-lamp">
      <div 
        className="blob1" 
        style={{
          content: '',
          position: 'absolute',
          borderRadius: '50%',
          filter: 'blur(100px)',
          opacity: 0.4,
          width: '700px',
          height: '700px',
          background: colors.blob1,
          top: '0%',
          left: '-10%',
          animation: 'float1 35s ease-in-out infinite',
        }}
      />
      <div 
        className="blob2"
        style={{
          content: '',
          position: 'absolute',
          borderRadius: '50%',
          filter: 'blur(100px)',
          opacity: 0.4,
          width: '600px',
          height: '600px',
          background: colors.blob2,
          top: '85%',
          right: '-15%',
          animation: 'float2 40s ease-in-out infinite',
        }}
      />
      <div 
        className="blob3"
        style={{
          content: '',
          position: 'absolute',
          borderRadius: '50%',
          filter: 'blur(100px)',
          opacity: 0.4,
          width: '650px',
          height: '650px',
          background: colors.blob3,
          top: '15%',
          left: '85%',
          animation: 'float3 38s ease-in-out infinite',
        }}
      />
    </div>
  );
};

export default LavaLamp;
