import React from 'react';
import './App.css';
import Portfolio from './components/Portfolio';
import { Routes, Route } from 'react-router-dom';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Layout from './components/Layout';

function App() {
  return (
    <Layout>
      <div className="min-h-screen relative pb-16">
        <Routes>
          <Route path="/" element={<Portfolio />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
        </Routes>
        <footer className="absolute bottom-0 w-full backdrop-blur-md bg-black/30 dark:bg-black/50 border-t border-white/5">
          <div className="py-2 text-center text-sm text-white/70">
            Designed & developed by Mudit Dubey. All rights reserved.
          </div>
        </footer>
      </div>
    </Layout>
  );
}

export default App;
