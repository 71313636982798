import React, { useContext, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { blogPosts } from '../data/blogPosts';
import ReactMarkdown from 'react-markdown';
import { useTheme } from '../context/ThemeContext';

const BlogPostPage = () => {
  const { slug } = useParams();
  const { isDarkMode } = useTheme();
  const post = blogPosts.find((post) => post.slug === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  if (!post) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-medium mb-4 text-current">Post not found</h1>
          <Link to="/blog" className="text-blue-500 hover:text-blue-600">
            Back to Blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-32 pb-16">
      <article className={`max-w-4xl mx-auto px-4 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
        {/* Header Section */}
        <header className="mb-8">
          <div className="flex flex-wrap gap-2 mb-4">
            {post.tags.map((tag) => (
              <span
                key={tag}
                className={`px-3 py-1 text-xs font-medium rounded-full backdrop-blur-sm ${
                  isDarkMode
                    ? 'bg-gray-700/30 text-gray-200 border border-gray-600/30'
                    : 'bg-gray-100/30 text-gray-800 border border-gray-200/30'
                }`}
              >
                {tag}
              </span>
            ))}
          </div>
          <h1 className={`text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {post.title}
          </h1>
          <div className={`text-sm mb-8 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {post.date} · {post.readTime} min read
          </div>
        </header>

        {/* Featured Image */}
        <div className="mb-12">
          <div className={`rounded-2xl overflow-hidden backdrop-blur-md shadow-lg border ${
            isDarkMode
              ? 'bg-gray-800/30 border-gray-700/30'
              : 'bg-white/30 border-gray-200/30'
          }`}>
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-[400px] object-cover object-center"
            />
          </div>
        </div>

        {/* Article Content */}
        <div className={`rounded-2xl backdrop-blur-md p-8 shadow-lg border ${
          isDarkMode
            ? 'bg-gray-800/30 border-gray-700/30'
            : 'bg-white/30 border-gray-200/30'
        }`}>
          <div className={`font-poppins ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
            <ReactMarkdown
              className="font-poppins"
              components={{
                h1: ({ children }) => (
                  <h1 className="text-4xl font-semibold mb-6 font-poppins">
                    {children}
                  </h1>
                ),
                h2: ({ children }) => (
                  <h2 className="text-3xl font-semibold mb-5 font-poppins">
                    {children}
                  </h2>
                ),
                h3: ({ children }) => (
                  <h3 className="text-2xl font-semibold mb-4 font-poppins">
                    {children}
                  </h3>
                ),
                p: ({ children }) => (
                  <p className="text-[0.9em] leading-relaxed mb-4 font-poppins">
                    {children}
                  </p>
                ),
                ul: ({ children }) => (
                  <ul className="list-disc pl-6 mb-4 space-y-2 font-poppins">
                    {children}
                  </ul>
                ),
                ol: ({ children }) => (
                  <ol className="list-decimal pl-6 mb-4 space-y-2 font-poppins">
                    {children}
                  </ol>
                ),
                li: ({ children }) => (
                  <li className="text-[0.9em] font-poppins">{children}</li>
                ),
              }}
            >
              {post.content}
            </ReactMarkdown>
          </div>
        </div>

        {/* Navigation */}
        <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-700">
          <Link
            to="/blog"
            className={`inline-flex items-center text-sm font-medium transition-colors duration-200 ${
              isDarkMode
                ? 'text-blue-400 hover:text-blue-300'
                : 'text-blue-600 hover:text-blue-500'
            }`}
          >
            ← Back to Blog
          </Link>
        </div>
      </article>
    </div>
  );
};

export default BlogPostPage;
